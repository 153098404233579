import * as React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import '../css/global.less';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import logo from '../img/KXP_logo_White.svg';


import '../i18n';
import Footer from '../components/Footer';
import MainMenu from '../components/layout/menu/main-menu';

const lngs = {
  en: { nativeName: 'enUs' },
  pt: { nativeName: 'ptBr' },
};

function Blog() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const query = useStaticQuery(graphql`
    {
      allWpPost {
        totalCount
        nodes {
          title
          id
          slug
          link
          excerpt
          internal{
            description
          }
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
      }
    }
  `);

  const hasImage = (node) => {
    if (node.featuredImage) {
      return true;
    }
    return false;
  };

  const siteUrl = 'https://app-kxp-www-prod-02.azurewebsites.net/';

  const sliderPosts = query.allWpPost.nodes.slice(-3);
  const cleanParagraphTag = (text) => {
    const cleanText = text.replace(/<p>/g, '');
    const limitText = cleanText.substring(0, 200);
    return limitText.replace(/<\/p>/g, '');
  };
  const slideImages = [
    {
      url: hasImage(sliderPosts[0]) ? `${siteUrl}/${sliderPosts[0].featuredImage.node.mediaItemUrl}` : '',
      postname: `${sliderPosts[0].title}`,
      postexcerpt: `${cleanParagraphTag(sliderPosts[0].excerpt)}`,
      postlink: `${sliderPosts[0].slug}`,
    },
    {
      url: hasImage(sliderPosts[1]) ? `${siteUrl}/${sliderPosts[1].featuredImage.node.mediaItemUrl}` : '',
      postname: `${sliderPosts[1].title}`,
      postexcerpt: `${cleanParagraphTag(sliderPosts[1].excerpt)}`,
      postlink: `${sliderPosts[1].slug}`,
    },
    {
      url: hasImage(sliderPosts[2]) ? `${siteUrl}/${sliderPosts[2].featuredImage.node.mediaItemUrl}` : '',
      postname: `${sliderPosts[2].title}`,
      postexcerpt: `${cleanParagraphTag(sliderPosts[2].excerpt)}`,
      postlink: `${sliderPosts[2].slug}`,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Blog da KXP</title>
        <meta name="description" content={t('description')} />
      </Helmet>
      <header>
        <a href="/"><img className="logo" src={logo} alt="KXP technology consulting" /></a>
        <MainMenu />
      </header>
      <div className="slide-container">
        <Slide>
          {slideImages.map((slideImage) => (
            <div className="each-slide" key={slideImage.id}>
              <div className="slider" style={{ backgroundImage: `url(${slideImage.url})` }}>
                <div className="slider_info_container">
                  <div className="slider_info">
                    <h2 className="accent-color">{slideImage.postname}</h2>
                    <p className="white">{slideImage.postexcerpt}</p>
                    <Link to={slideImage.postlink} className="white_button">{t('blog.readMore')}</Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slide>
      </div>
      <article className="blog_section_bar">
        <section>
          <h2 className="blue">{t('blog.lastPosts')}</h2>
        </section>
        <section>
          <hr className="blue_bar" />
        </section>
      </article>

      <div className="posts_container">
        {query.allWpPost.nodes.map((post) => (
          <div className="post_img_block" key={post.id} style={hasImage(post) ? { backgroundImage: `url(${siteUrl}${post.featuredImage.node.mediaItemUrl})` } : {}}>
            {/* <Link to={post.slug}>{post.title}</Link> */}
            <div className="post_info_block">
              <h3 className="accent-color">{post.title}</h3>
              <p className="white">{cleanParagraphTag(post.excerpt)}</p>
              <Link to={post.slug} className="white_button">{t('blog.readMore')}</Link>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
}

export default Blog;
